<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="订单号：">
          <el-input style="width: 200px" clearable v-model="searchData.order_sn" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="用户手机号码：">
          <el-input style="width: 200px" clearable v-model="searchData.user_mobile" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="管理员手机号码：">
          <el-input style="width: 200px" clearable v-model="searchData.manage_mobile" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select style="width: 200px" v-model="searchData.type" placeholder="请选择" size="mini" clearable>
            <el-option label="存储" :value="0"> </el-option>
            <el-option label="取出" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期：">
          <el-date-picker
            style="width: 200px"
            v-model="timeSlot"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="list">
      <el-table-column prop="order_sn" label="订单号" min-width="150"> </el-table-column>
      <el-table-column prop="jifen" label="积分" min-width="120"> </el-table-column>
      <el-table-column prop="content" label="类型" min-width="110">
        <template slot-scope="{ row }">
          <el-tag type="primary" v-if="row.type == 0" size="small">{{ row.type_dsc }}</el-tag>
          <el-tag type="success" v-if="row.type == 1" size="samll">{{ row.type_dsc }}</el-tag>
        </template>
      </el-table-column>
<!--      <el-table-column prop="content" label="状态" min-width="110">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-tag type="warning" v-if="row.order_status == 0" size="small">待核销</el-tag>-->
<!--          <el-tag type="success" v-if="row.order_status == 1" size="small">已核销</el-tag>-->
<!--          <el-tag type="danger" v-if="row.order_status == 2" size="small">已取消</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="content" label="用户" min-width="150">
        <template slot-scope="{ row }">
          <div v-if="row.user">
            <img class="face" :src="row.user.face" alt="" />
            <div class="user-info">{{ row.user && row.user.nickname }}</div>
            <div class="user-info">{{ row.user && row.user.mobile }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="管理员" min-width="150">
        <template slot-scope="{ row }">
          <div v-if="row.manager">
            <img class="face" :src="row.manager.face" alt="" />
            <div class="user-info">{{ row.manager && row.manager.nickname }}</div>
            <div class="user-info">{{ row.manager && row.manager.mobile }}</div>
          </div>
          <div v-else>平台管理员</div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" min-width="160"> </el-table-column>
      <el-table-column prop="created_at" label="创建时间" min-width="160"> </el-table-column>
      <!-- <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link type="primary" :underline="false" @click="handleQrcode(row)">二维码</el-link>
        </template>
      </el-table-column> -->
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />

    <!-- 二维码 -->
    <el-dialog title="二维码" :visible.sync="show_code" width="width">
      <div class="code-box">
        <el-image style="width: 150px; height: 150px" :src="qrcode_url" fit="cover"></el-image>
      </div>
      <div slot="footer">
        <el-button @click="show_code = false" size="small">取 消</el-button>
        <el-button type="primary" @click="show_code = false" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getListAPI, getDetailAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],

  data() {
    return {
      total: 0,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        order_sn: '',
        user_mobile: '', // 用户手机号码
        manage_mobile: '', // 用户手机号码
        type: '', //0 存储 1 取出
      },
      timeSlot: [],
      qrcode_url: '',
      show_code: false
    }
  },

  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    handleQrcode({ hexiao_qrcode_url }) {
      this.qrcode_url = hexiao_qrcode_url
      this.show_code = true
    },
    handleClickTab(val) {
      if (this.searchData.order_status == val) return
      this.searchData.order_status = val
      this.getList()
    },
    async getList() {
      const { timeSlot } = this
      let params = { ...this.searchData }
      if (timeSlot && timeSlot.length > 0) {
        params.created_at_start = timeSlot[0]
        params.created_at_end = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },

    reset() {
      this.timeSlot = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .top-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
  .face {
    width: 50px;
    border-radius: 50%;
    position: absolute;
  }
  .user-info {
    padding-left: 60px;
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 500;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .code-box {
    display: flex;
    justify-content: center;
  }
}
</style>
