import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/store/order/jifen/list`,
    method: 'get',
    params
  })
}

// 详情
export function getDetailAPI(id) {
  return http({
    url: `/api/store/order/jifen/detail`,
    method: 'get',
    params: { id }
  })
}
